/** @define blog;  weak */
.blog__item {
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  text-align: center;
}
@media only screen and (max-width: 599px) {
  .blog__item {
    border-bottom: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 600px) and (max-width: 899px) {
  .blog__item {
    border-bottom: 1px solid #bebebe;
  }
  .blog__item:nth-child(odd) {
    border-left: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .blog__item {
    border-bottom: 1px solid #bebebe;
  }
  .blog__item:nth-of-type(3n + 1) {
    border-left: 1px solid #bebebe;
  }
  .blog__item:nth-of-type(3n - 1) {
    border-right: 1px solid #bebebe;
  }
  .blog__item:last-child {
    border-right: none;
  }
}
@media only screen and (min-width: 1200px) {
  .blog__item {
    border-bottom: 1px solid #bebebe;
  }
  .blog__item:nth-of-type(2n + 1) {
    border-left: 1px solid #bebebe;
  }
  .blog__item:nth-of-type(4n - 1) {
    border-right: 1px solid #bebebe;
  }
  .blog__item:last-child {
    border-right: none;
  }
}
.blog__item .title {
  margin-bottom: 10px;
  font-size: 20px;
}
.blog__item:hover {
  cursor: pointer;
}
.blog__item:hover .link {
  background-color: #229660;
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .blog--related .blog__item:nth-of-type(3n + 1) {
    border-left: none;
  }
  .blog--related .blog__item:nth-of-type(3n - 1) {
    border-right: none;
  }
  .blog--related .blog__item:nth-of-type(3n + 2) {
    border-left: 1px solid #bebebe;
  }
  .blog--related .blog__item:nth-of-type(3n - 1) {
    border-right: 1px solid #bebebe;
  }
}
@media only screen and (min-width: 1200px) {
  .blog--related .blog__item:nth-of-type(2n + 1) {
    border-left: none;
  }
  .blog--related .blog__item:nth-of-type(4n - 1) {
    border-right: none;
  }
  .blog--related .blog__item:nth-of-type(2n + 2) {
    border-left: 1px solid #bebebe;
  }
  .blog--related .blog__item:nth-of-type(4n - 2) {
    border-right: 1px solid #bebebe;
  }
}
@media only screen and (max-width: 799px) {
  .blog--view {
    margin-top: 30px;
  }
}
.blog--view .blog__item {
  width: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 899px) {
  .blog--view .blog__item {
    width: 50%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .blog--view .blog__item {
    width: calc(100% / 3);
  }
}
@media only screen and (min-width: 1200px) {
  .blog--view .blog__item {
    width: 25%;
  }
}
.blog--view .blog__content {
  flex: 1;
}
.blog--view .blog__link {
  padding: 0 30px 30px;
}
.blog__itemfirst {
  width: 100%;
  border: none;
  border-bottom: 1px solid #bebebe;
  /*@media only screen and (min-width: 900px) {
    flex: 1;
  }*/
}
@media only screen and (max-width: 799px) {
  .blog__itemfirst {
    flex-direction: column;
    align-items: center;
    padding: 0 20px 40px 20px;
    text-align: center;
  }
}
@media only screen and (min-width: 800px) {
  .blog__itemfirst {
    padding: 40px 20px 40px 0;
  }
}
.blog__itemfirst .blog__picture {
  flex: 0 0 340px;
  margin-right: 40px;
}
@media only screen and (max-width: 799px) {
  .blog__itemfirst .blog__picture {
    flex-basis: 120px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 1000px) {
  .blog__itemfirst .blog__picture {
    flex: 0 0 500px;
  }
}
.blog__itemfirst .title {
  margin-bottom: 24px;
}
/*# sourceMappingURL=css/blog.css.map */